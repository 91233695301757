import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Typography from '@mui/joy/Typography';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import { popupStore } from '../../../stores/popupStore';

// 1. 버튼 정의
// outline 버튼 1 => 이벤트 존재, 필수 아님 // solid 버튼 1 => 이벤트 존재 필수임
// ===> 이벤트, 버튼 존재 유무, 버튼 안 텍스트, 각 버튼에 해당하는 이벤트 => props
// 상단에 'X' 닫는 버튼
// 2. 각 내용에 맞는 title 필요
// 3. 각 내용은 props로 내려받기
// 4. w-480, w-640, w-1024 별로 크기가 달라짐 => 반응형인데 할 필요가 있을까?
// 5. popup, panel 형태 두개로 modal 관리 => props

// 사용방법
// popup을 사용할 위치에 popupStore에서 openPopup action을 꺼내온 후 props 전달
// 예시 ==> onClick={() => openPopup('에스크로 서비스 가입사실 확인', <FooterAscro />, '확인')}
// ModalLog => layout에 사용할 용도 정해서 사용
// 각 props에 대한 설명은 store 내에 명시
export default function PopUp() {
  const {
    popupLayout,
    popupTitle,
    isPopupOpen,
    popupContent,
    closePopup,
    solidText,
    outlineText,
    solidEvent,
    outlineEvent,
    useBottomButton,
  } = popupStore();

  const { pathname } = useLocation();

  useEffect(() => {
    closePopup();
  }, [pathname, closePopup]);

  const checkEvent = (excute: any, close: () => void) => async () => {
    if (excute) {
      excute();
      close();
    } else {
      close();
    }
  };

  return (
    <>
      <Transition in={isPopupOpen} timeout={400} cl>
        {(state) => (
          <Modal
            keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={closePopup}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms`,
                  ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                  }[state],
                },
              },
            }}>
            <ModalDialog
              layout={popupLayout}
              aria-labelledby="nested-modal-title"
              aria-describedby="nested-modal-description"
              // 오른쪽에서 옆으로 나오는 sldie 옵션
              // sx={
              //   popupLayout === 'panel'
              //     ? {
              //         top: '50%',
              //         right: ['entered', 'exiting'].includes(state) ? '0' : '100%',
              //         transform: 'translate(0, -50%)',
              //         transition: 'left 3000ms',
              //         position: 'absolute',
              //       }
              //     : {
              //         transform: 'translate(-50%, -50%)',
              //         top: '50%',
              //         left: '50%',
              //       }
              // }
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  level="h1"
                  sx={{
                    fontSize: 25,
                  }}>
                  {popupTitle}
                </Typography>

                <IconButton
                  size="lg"
                  variant="plain"
                  color="neutral"
                  sx={{ ml: 'auto', color: '#000' }}
                  onClick={closePopup}>
                  <Close />
                </IconButton>
              </Box>

              {popupContent}
              {useBottomButton && (
                <Box
                  sx={{
                    mt: 1,
                    display: 'flex',
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row-reverse' },
                  }}>
                  {solidText && (
                    <Button variant="solid" color="primary" onClick={checkEvent(solidEvent, closePopup)}>
                      {solidText}
                    </Button>
                  )}
                  {outlineText && (
                    <Button variant="outlined" color="neutral" onClick={checkEvent(outlineEvent, closePopup)}>
                      {outlineText}
                    </Button>
                  )}
                </Box>
              )}
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </>
  );
}
