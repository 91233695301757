const Service = () => {
  return (
    <div>
      <div className="search-wrapper scroll-y mt-16">
        <br />
        <p className="title">제 1 조 (목적)</p>
        <p>
          본 이용약관은 “market-wiz.ocm”(이하 "사이트")의 서비스의 이용조건과 운영에 관한 제반사항 규정을 목적으로
          합니다.
        </p>
        <p className="title">제 2 조 (용어의 정의)</p>
        <p>본약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.</p>
        <p>① 이용자 : 사이트에 접속하여 본 약관에 따라 마켓위즈가 제공하는 사이트를 이용하는 자를 말합니다. ‍</p>
        <p>② ‍운영자 :‍ 사이트를 개설하여 운영하는 운영자를 말합니다.</p>
        <p className="title">제 3 조 (약관 외 준칙)</p>
        <p>
          운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수 있으며,본 약관과 운영정책이 중첩될 경우 운영정책이 우선
          적용됩니다.
        </p>
        <p className="title">제 4 조 (개인정보처리방침)</p>
        <p>
          운영자는 관계법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위하여 노력을 합니다. 이용자의
          개인정보보호에 관하여 관계법령 및 사이트가 정하는 개인정보처리방침에 정한 바에 따릅니다. 단,‍이용자의
          귀책사유로 인해 노출된 정보에 대해 운영자는 일체의 책임을 지지 않습니다. 운영자는 이용자가 미풍양속에
          저해되거나 국가안보에 위배되는 게시물 등 위법한 게시물을 등록 ·배포할 경우 관련기관의 요청이 있을 시 이용자의
          자료를 열람 및 해당 자료를 관련기관에 제출할 수 있습니다.
        </p>
        <p className="title">제 5 조 (운영자의 의무)</p>
        <p>
          ① 운영자는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 가급적 빨리 처리하여야
          합니다.다만,개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에 공지 및 팝업 게시 등 최선을 다합니다.
        </p>
        <p>
          ② 운영자는 계속적이고 안정적인 사이트 제공을 위하여 설비에 장애가 생기거나 유실된 때에는 이를 지체 없이 수리
          또는 복구할 수 있도록 사이트에 요구할 수 있습니다.다만, 천재지변 또는 사이트나 운영자에 부득이한 사유가 있는
          경우,사이트 운영을 일시 정지할 수 있습니다.
        </p>
        <p className="title">제 6 조 (이용자의 의무)</p>
        <p>
          ① 이용자는 본 약관에서 규정하는 사항과 운영자가 정한 제반규정,공지사항 및 운영정책 등 사이트가 공지하는 사항
          및 관계법령을 준수하여야 하며,기타 사이트의 업무에 방해가 되는 행위,사이트의 명예를 손상시키는 행위를 해서는
          안됩니다.
        </p>
        <p className="title">제 7 조 (서비스 이용시간)</p>
        <p>
          ① 서비스 이용시간은 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.단,사이트는
          시스템 정기점검,증설 및 교체를 위해 사이트가 정한 날이나 시간에 서비스를 일시중단 할 수 있으며 예정된 작업으로
          인한 서비스 일시 중단은 사이트의 홈페이지에 사전에 공지하오니 수시로 참고하시길 바랍니다.
        </p>
        <p>
          ② 단,사이트는 다음 경우에 대하여 사전 공지나 예고없이 서비스를 일시적 혹은 영구적으로 중단할 수 있습니다.
          -‍긴급한 시스템 점검,‍증설,‍교체,‍고장 혹은 오동작을 일으키는 경우 -‍국가비상사태,‍정전,‍천재지변 등의
          불가항력적인 사유가 있는 경우 -‍전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한 경우
          -‍서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우
        </p>
        <p className="title">제 8 조 (서비스 이용 제한)</p>
        <p>①‍ 타인의 사이트 이용을 방해하거나 정보를 도용하는 행위 </p>
        <p>‍② 사이트의 운영진,‍직원 또는 관계자를 사칭하는 행위</p>
        <p>③‍ 사이트,‍기타 제3자의 인격권 또는 지적재산권을 침해하거나 업무를 방해하는 행위 </p>
        <p>
          ④‍ 다른 이용자에 대한 개인정보를 그 동의 없이 수집,‍저장,‍공개하는 행위 ⑤‍ 범죄와 결부된다고 객관적으로
          판단되는 행위
        </p>
        <p>⑥‍ 기타 관련 법령에 위배되는 행위</p>
        <p className="title">제 9 조 (서비스이용)</p>
        <p>
          ① 이용자는 운영자가 정한 별도의 인증 방법을 통해 회원가입을 한 후 서비스를 무료 혹은 유료로 즉시 이용할 수
          있습니다.
          <br /> ② 이용자는 “웹사이트”를 통한 회원가입 후 생성된 “서비스” 내 “마켓위즈” 계정 1개에 등록한 "서비스"에
          따른 사업자 연결이 가능하며, 1개의 사업자가 복수의 “마켓위즈” 계정을 생성, 이용할 수는 없습니다.
          <br /> ③ 유료 서비스 이용 요금 및 결제 방식은 해당 서비스에 명시되어 있는 규정에 따릅니다.
        </p>
        <p className="title">제 10 조 (데이터 저장)</p>
        <p>
          ① 운영자는 이용자가 “서비스”를 이용하는 과정에서 클라우드 인프라를 기반으로 “데이터”를 저장할 수 있습니다.{' '}
          <br />② 운영자는 제1항에 따라 저장한 “데이터”를 통하여 이용자에게 상품 관련 분석 정보를 대시보드 형태로 제공할
          수 있습니다.
        </p>
        ‍<p className="title">제 11 조 (데이터 보호 및 관리)</p>
        <p>
          ① 운영자는 이용자의 “데이터”를 보호하며 고객 데이터에 대한 허락 받지 않은 접근을 방지하기 위해 최소 업계 표준
          시스템 및 절차를 구현합니다. <br /> ② 운영자에 저장된 “데이터”는 수사기관의 영장, 법원의 판결 또는 결정 등
          정당한 법률적 절차가 개시된 경우 별도의 이용자 동의가 없는 경우라도 요청 기관에 이용자 및 이용자이 소속된
          이용기관의 “데이터”를 제공하거나 공개할 수 있습니다.
          <br /> ③ 운영자는 운영자의 책임이 없는 사유로 인한 “데이터” 유실에 대해 책임지지 않습니다. 운영자의 책임이
          없는 사유의 예시는 다음과 같습니다.
          <br /> 1. 이용자의 PC에서 다루는 제3자 제공 프로그램의 종료 및 오류로 인하여, 파일이 손상되었거나 서버에
          업로드 된 파일이 잘못 저장된 경우 <br />
          2. 이용자의 PC혹은 스마트폰 등에서 업로드 시 사용한 네트워크 연결상태 및 네트워크 환경 상의 장애 및 오류
          등으로 인하여 정상적으로 파일이 업로드 되지 않은 경우
          <br /> 3. 이용자의 PC/네트워크 등 환경 문제, 아이디/비밀번호 분실 및 도용, 관리소홀 등 사용자 책임이 있는 경우
          <br />
          4. 이용자가 “데이터”를 백업, 저장하지 아니하여 “데이터’가 유실된 경우 <br />
          5. 천재지변, 국가비상사태 또는 이에 준하는 불가항력적인 사유로 인한 서버파일 손상이 있는 경우
          <br /> 6. 기타 위 사례를 포함하여, 운영자의 책임이 없는 사유로 판단될 수 있는 경우
          <br /> ④ 유실된 데이터는 복구가 불가능하니 “데이터”는 이용자가 별도로 보관하여야 합니다. <br />⑤ 이용자가
          “마켓위즈 계정”이용중 탈퇴시 운영자는 이용자의 "데이터"를 삭제합니다.
        </p>
        <p className="title">제 12 조 (손해배상)</p>
        <p>
          ①‍ 본 사이트의 발생한 모든 민,형법상 책임은 이용자 본인에게 1차적으로 있습니다.
          <br /> ② 본 사이트로부터 이용자가 받은 손해가 천재지변 등 불가항력적이거나 이용자의 고의 또는 과실로 인하여
          발생한 때에는 손해배상을 하지 않습니다.
        </p>
        <p className="title">제 13 조 (면책)</p>
        <p>
          ① 운영자는 이용자가 사이트의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스 자료에 대한 취사선택
          또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
          <br /> ② 운영자는 본 사이트의 서비스 기반 및 타 통신업자가 제공하는 전기통신서비스의 장애로 인한 경우에는
          책임이 면제되며 본 사이트의 서비스 기반과 관련되어 발생한 손해에 대해서는 사이트의 이용약관에 준합니다. <br />
          ③‍ 운영자는 이용자가 저장,‍게시 또는 전송한 자료와 관련하여 일체의 책임을 지지 않습니다. <br />④ 운영자는
          이용자의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임지지 아니합니다.
          <br /> ⑤ 운영자는 이용자 상호간 또는 회원과 제3자 상호간,기타 회원의 본 서비스 내외를 불문한 일체의
          활동(데이터 전송,기타 커뮤니티 활동 포함)에 대하여 책임을 지지 않습니다. <br />⑥ 운영자는 이용자의 게시 또는
          전송한 자료 및 본 사이트로 이용자가 제공받을 수 있는 모든 자료들의 진위,신뢰도,정확성 등 그 내용에 대해서는
          책임지지 아니합니다.
          <br /> ⑦ 운영자는 이용자 상호간 또는 이용자과 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우에
          그로부터 발생하는 일체의 손해에 대하여 책임지지 아니합니다. <br />⑧ 운영자는 운영자의 귀책사유 없이 이용자간
          또는 이용자과 제3자간에 발생한 일체의분쟁에 대하여 책임지지 아니합니다.
          <br /> ⑨ 운영자는 서버 등 설비의 관리,점검,보수,교체 과정 또는 소프트웨어의 운용 과정에서 고의 또는 고의에
          준하는 중대한 과실 없이 발생할 수 있는 시스템의 장애,제3자의 공격으로 인한 시스템의 장애,국내외의 저명한
          연구기관이나 보안관련 업체에 의해 대응방법이 개발되지 아니한 컴퓨터 바이러스 등의 유포나 기타 운영자가 통제할
          수 없는 불가항력적 사유로 인한 이용자의 손해에 대하여 책임지지 않습니다.
        </p>
        <p className="title">부칙</p>
        <p className="title">이 약관은 {`<2025.01.01>`}부터 시행합니다.</p>
      </div>
    </div>
  );
};

export default Service;
