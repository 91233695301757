import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { popupStore } from '../../../stores/popupStore';
import solutionStore from '../../../stores/solutionStore';
import userStore from '../../../stores/userStore';
import { successBar } from '../../Util/SnackBar/SnackBar';
import MemberInfo from '../../frontOffice/MemberInfo';
import ChangeSolution from '../../sellerOffice/managePreference/service/ChangeSolution';
import RemindSolution from '../../sellerOffice/managePreference/service/RemindSolution';

const IntroLayoutHeader = () => {
  const { unsetLogin } = userStore();
  const { userSolution, userPreviousSolution, unsetSolution } = solutionStore();
  const {
    userSession: accessToken,
    userEmail,
    spmName,
    socialType,
    solutionEndDate,
    soltHistory,
  } = userStore().getUserInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const { openPopup } = popupStore();
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const now = dayjs();
  const remainDate = userSolution?.useEndDate ? dayjs(userSolution.useEndDate).diff(now, 'days') : 0;

  console.log('IntroLayoutHeader', userPreviousSolution, soltHistory, spmName);
  console.log('ENV : ', process.env.REACT_APP_ENV);

  useEffect(() => {
    if (accessToken !== '' && location?.state?.isLoginSuccess) {
      if (soltHistory && solutionEndDate !== undefined) {
        setIsExpired(true);
      }
    }
  }, [accessToken, location?.state?.isLoginSuccess, soltHistory, solutionEndDate]);

  useEffect(() => {
    if (isExpired && userSolution !== null) {
      openPopup('center', '솔루션 만료 안내', <RemindSolution />);
    }
  }, [isExpired, userSolution, openPopup]);

  const handleClickSignOut = () => {
    unsetSolution();
    unsetLogin();
    successBar('로그아웃 되었습니다.');
    navigate('/');
  };

  const scrollView = (elemID: string) => async () => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        document.getElementById(elemID).scrollIntoView({
          behavior: 'smooth',
        });
      }, 200);
      return;
    }
    document.getElementById(elemID).scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleUserInfo = () => {
    openPopup('center', '회원정보', <MemberInfo />);
  };
  const handleClickChangeSolution = () => {
    openPopup('panel', '솔루션 변경', <ChangeSolution solutionNo={userSolution?.solutionProductNo} />);
  };

  const checkValidateSpmName = !(typeof spmName === 'undefined' || spmName === null || spmName === '');

  return (
    <div className="header-space fo">
      <div className="head-left">
        <h1 className="logo">
          <Link to={'/'} title="홈페이지 바로가기">
            logo
          </Link>
        </h1>
      </div>
      <div className="head-center">
        <ul>
          <li>
            <button onClick={scrollView('anchor1')} title="마켓위즈 소개 페이지로 이동">
              마켓위즈소개
            </button>
          </li>
          <li>
            <button onClick={scrollView('anchor2')} title="마켓위즈 서비스 특장점 페이지로 이동">
              서비스 특장점
            </button>
          </li>
          <li>
            <button onClick={scrollView('anchor3')} title="마켓위즈 요금안내 페이지로 이동">
              요금안내
            </button>
          </li>
          <li>
            <Link to={'/support/notice'} title="마켓위즈 고객센터 페이지로 이동">
              고객센터
            </Link>
          </li>
        </ul>
      </div>
      <div className="head-right">
        {accessToken !== '' ? (
          <>
            <i
              className={
                socialType === 'naver'
                  ? 'ico-square-naver'
                  : socialType === 'kakao'
                  ? 'ico-square-kakao'
                  : 'ico-square-google'
              }></i>
            <span onClick={handleUserInfo} className="user-info">
              {userEmail}
            </span>
            <button onClick={handleClickSignOut} className="log-in">
              로그아웃
            </button>
            {!checkValidateSpmName ? (
              <Link to="/member/register-solution/" className="button small bg-primary">
                솔루션 신청
              </Link>
            ) : remainDate > 0 ? (
              <Link to="/sellerOffice" className="button small bg-primary">
                셀러 오피스
              </Link>
            ) : (
              <button
                disabled={userPreviousSolution === null}
                onClick={handleClickChangeSolution}
                className="button small bg-primary">
                솔루션 신청
              </button>
            )}
          </>
        ) : (
          <>
            <Link to="/auth/login" className="log-in">
              로그인
            </Link>
            <Link to="/member/register" className="join-us">
              회원가입
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default IntroLayoutHeader;
