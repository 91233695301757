import { css } from 'styled-components';
export type WhiteBoardType = {
  radius?: number;
};
const WhiteBoard = css<WhiteBoardType>`
  background-color: #fff;
  border-radius: ${(p) => `${p.radius ?? 0}px`};
`;

export type StatusColorVariation =
  | 'black'
  | 'negative'
  | 'positive'
  | 'progress'
  | 'gray'
  | 'darkgray'
  | 'date'
  | 'link'
  | 'none';
export type StatusColorType = {
  $color?: StatusColorVariation;
};
export const StatusColor = css<StatusColorType>`
  ${(p) =>
    p.$color === 'negative' &&
    css`
      color: ${(p) => p.theme.color.negative};
    `}
  ${(p) =>
    p.$color === 'positive' &&
    css`
      color: ${(p) => p.theme.color.positive};
    `}
      ${(p) =>
    p.$color === 'progress' &&
    css`
      color: ${(p) => p.theme.color.progress};
    `}
      ${(p) =>
    p.$color === 'darkgray' &&
    css`
      color: ${(p) => p.theme.grayScale.gray7};
    `}
    ${(p) =>
    p.$color === 'gray' &&
    css`
      color: ${(p) => p.theme.grayScale.gray5};
    `}
    ${(p) =>
    p.$color === 'black' &&
    css`
      color: ${(p) => p.theme.grayScale.gray9};
    `} 
    ${(p) =>
    p.$color === 'date' &&
    css`
      color: ${(p) => p.theme.color.date};
    `}
    ${(p) =>
    p.$color === 'link' &&
    css`
      color: ${(p) => p.theme.color.link};
    `}
    ${(p) =>
    p.$color === 'none' &&
    css`
      color: ${(p) => p.theme.color.none};
    `}
`;
export type InputContainerType = 'form' | 'dashboard';
export type InputContainerProps = {
  $form?: 'form' | 'dashboard';
  $maxSize?: boolean;
  $borderColor?: string;
  $disabled?: boolean;
};
const InputHeight = css<InputContainerProps>`
  ${(p) =>
    p.$form === 'form' &&
    css`
      height: ${(p) => p.theme.layout.form_input_height};
    `}
  ${(p) =>
    p.$form === 'dashboard' &&
    css`
      height: ${(p) => p.theme.layout.dashboard_input_height};
    `}
`;

export type BackgroundVariationType = {
  $background?: 'transparent' | 'primary_bg' | 'positive_bg' | 'progress_bg' | 'none_bg' | 'purple_bg';
};
const BackgroundVariation = css<BackgroundVariationType>`
  ${(p) =>
    p.$background === 'transparent' &&
    css`
      background-color: transparent;
    `}
  ${(p) =>
    p.$background === 'primary_bg' &&
    css`
      background-color: ${(p) => p.theme.color.primary_bg};
    `}
  ${(p) =>
    p.$background === 'positive_bg' &&
    css`
      background-color: ${(p) => p.theme.color.positive_bg};
    `}
    ${(p) =>
    p.$background === 'progress_bg' &&
    css`
      background-color: ${(p) => p.theme.color.progress_bg};
    `}
    ${(p) =>
    p.$background === 'none_bg' &&
    css`
      background-color: ${(p) => p.theme.color.none_bg};
    `}
    ${(p) =>
    p.$background === 'purple_bg' &&
    css`
      background-color: ${(p) => p.theme.color.purple_bg};
    `}
`;

export type FontWeightVariation = '900' | '800' | '700' | '600' | '500' | '400' | '300' | '200' | '100';
export type FontWeightType = {
  $weight?: FontWeightVariation;
};
const FontWeight = css<FontWeightType>`
  font-weight: ${(p) => p.$weight};
`;

const InputBoxStyle = css`
  width: 100%;
  border: 1px solid ${(p) => p.theme.input.line};
  border-radius: 0.4rem;
  background-color: #fff;
  padding-inline: 1.4rem;
`;

const ScrollStyle = css`
  margin-right: -10px;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }
`;
export const mixin: any = {
  WhiteBoard,
  StatusColor,
  BackgroundVariation,
  InputHeight,
  InputBoxStyle,
  FontWeight,
  ScrollStyle,
};
