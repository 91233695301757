const Privacy = () => {
  return (
    <div>
      <div className="search-wrapper scroll-y mt-16">
        <p>
          (주)마켓위즈(이하 ‘회사’)은 관련 법령에 따라 마켓위즈 서비스 및 이에 부수하는 제반 서비스(통칭하여 이하
          ‘서비스’)를 이용하는 이용자의 개인정보를 보호하고, 이와 관련한 고충을 신속하고 원활하게 처리하기 위하여 다음과
          같이 개인정보 처리방침을 수립·공개합니다.
        </p>
        <br />
        <p className="title">제 1 조 (개인정보의 처리 목적)</p>
        <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다.</p>
        <p>
          1. 회원 가입 및 관리: 회원가입의사 확인, 본인 식별인증, 연령인증, 회원자격 유지 및 관리, 서비스 부정이용 방지
          만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지통지 <br />
          2. 재화 또는 서비스 제공: 물품 배송, 기본/맞춤형 서비스 제공, 계약서청구서 발송, 본인인증, 연령인증, 요금결제
          및 정산, 채권추심 <br />
          3. 고충처리: 이용자의 신원 확인, 고충사항 확인, 사실조사를 위한 연락통지, 처리결과 통보 <br />
          4. 마케팅 및 광고에의 활용: 맞춤형 광고 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 <br />
          5. 서비스 개선 및 개발: 기존 서비스 개선 및 신규 서비스, 맞춤형 서비스 개발
          <br /> 6. 가명정보의 활용: 통계작성, 과학적 연구, 공익적 기록보존 등을 위한 가명처리 및 가명정보의 활용
        </p>
        <p className="title">제 2 조 (처리하는 개인정보 항목)</p>
        <p>① 회사는 서비스 이용자에 대하여 다음의 개인정보항목을 수집하여 처리하고 있습니다.</p>
        <p>
          1. 회원 가입 시 기본수집사항(필수항목): 이메일, 로그인ID, 이름, 프롬프트에 입력한 텍스트 내용 정보와 AI 엔진이
          출력한 텍스트 내용 정보 <br />
          2. 만 14세 미만 아동의 회원 가입 시 기본수집사항(필수항목): 법정대리인 이름, 법정대리인 휴대전화번호 <br />
          3. 회원 가입 선택 수단에 따라 회원 가입 승인 목적에서 제휴사로부터 추가로 제공받는 사항
        </p>
        <br />
        <table border={1}>
          <thead>
            <tr>
              <th>회원 가입 시 이용자가 선택한 제휴사/서비스</th>
              <th>필수 제공 항목</th>
              <th>선택 제공 항목</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center', paddingBlock: '2rem' }}>
            <tr>
              <td>Google ID로 회원가입(제휴사: Google LLC.)</td>
              <td>이메일, 이름, 휴대전화번호</td>
              <td></td>
            </tr>
            <tr>
              <td>NAVER ID로 회원가입(제휴사: 네이버 주식회사)</td>
              <td>이메일, 이름, 휴대전화번호</td>
              <td>성별</td>
            </tr>
            <tr>
              <td>Kakaotalk으로 회원가입(제휴사: 주식회사 카카오)</td>
              <td>이메일, 이름, 휴대전화번호</td>
              <td>성별</td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          1. 수집·처리하는 개인정보의 항목 및 수집 방법 ◦ 회사는 다음의 개인정보 항목을 정보주체의 동의 없이 처리하고
          있습니다.
        </p>
        <br />
        <table border={1}>
          <thead>
            <tr>
              <th>법적 근거</th>
              <th>수집, 이용 목적</th>
              <th>수집 항목</th>
              <th>보유 및 이용기간</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'center' }}>
            <tr>
              <td>개인정보 보호법 제15조 제1항 제4호 (계약의 이행)</td>
              <td>마켓위즈 서비스 결제 처리</td>
              <td>아이디, 구매내역, 결제정보</td>
              <td>5년(전자상거래법 제6조)</td>
            </tr>
            <tr>
              <td>개인정보 보호법 제15조 제1항 제4호 (계약의 이행)</td>
              <td>마켓위즈 서비스에 대한 문의, 상담</td>
              <td>이름, 구매내역</td>
              <td>3년(전자상거래법 제6조)</td>
            </tr>
          </tbody>
        </table>
        <p>
          2. 중복 계정 생성을 방지하기 위한 수집사항(필수항목) : 휴대전화번호 <br />
          3. 서비스 이용과정에서 아래 개인정보 항목이 자동으로 : IP주소, 쿠키, 서비스 이용 기록(방문 및 이용기록, 불량
          이용기록 등), 기기정보(휴대폰 모델명, OS 명 및 버전정보), 광고식별자 <br />
          4. 고충처리 시: 이용자로부터 위 각 정보 중 필요한 항목 및 해당 고충처리에 필요한 별개 항목을 수집 및 처리
        </p>
        <p className="title">제 3 조 (개인정보의 처리 및 보유기간)</p>
        <p>
          ① 회사는 개인정보의 수집 및 이용목적이 달성되거나, 이용자가 서비스를 탈퇴 또는 이용자격을 상실할 경우에는
          별도의 요청이 없더라도 수집된 이용자의 정보를 지체없이 삭제 및 파기합니다. 다만, 회원 탈퇴 또는 이용자격
          상실에도 불구하고 다음의 정보에 대해서는 아래의 이유로 보존합니다.
        </p>
        <p>
          1. 관계 법령 위반에 따른 수사조사 등이 진행 중인 경우에는 해당 수사·조사 종료 시까지 <br />
          2. 앱 이용에 따른 채권·채무관계 잔존 시에는 해당 채권·채무관계 정산 시까지
          <br /> 3. 회사가 이용약관에 따라 서비스 이용계약을 해지한 경우 부정한 재가입 및 서비스 이용을 방지하기 위하여
          서비스
        </p>
        <p>② 전항에도 불구하고 회사는 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지 보존합니다.</p>
        <p>
          1. 서비스 이용 관련 개인정보 (로그기록): 「통신비밀보호법」상 보존기간인 3개월 <br />
          2. 계약 또는 청약철회 등에 관한 기록 및 대금결제 및 재화 등의 공급에 관한 기록: 「전자상거래 등에서의
          소비자보호에 관한 법률, 상 보존기간인 5년 <br />
          3. 소비자의 불만 또는 분쟁처리에 관한 기록: 「전자상거래 등에서의 소비자보호에 관한 법률」상 보존기간인 3년
          <br /> 4. 표시 광고에 관한 기록: 「전자상거래 등에서의 소비자보호에 관한 법률」상 보존기간인 6개월 <br />
          5. 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류: 「국세기본법」상 보존기간인 5년
        </p>
        <p>
          ③ 회사는 1년 또는 이용자가 별도로 정한 기간(3년) 동안 서비스를 이용하지 않은 이용자의 개인정보를 별도로
          분리보관 또는 삭제하여 관리합니다.
        </p>
        <p className="title">제4조 (개인정보의 제3자 제공)</p>
        <p>
          회사는 이용자의 동의를 받거나 개인정보 보호법 또는 다른 법률의 특별한 규정이 있는 경우에만 개인정보를
          제3자에게 제공합니다.
        </p>
        <p className="title">제5조 (개인정보처리의 위탁)</p>
        <p>① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.</p>
        <p>
          위탁받는 자(수탁자) 위탁하는 업무의 내용 ㈜카카오, ㈜네이버 고지사항 전달, 신규 서비스(제품) 개발 및 맞춤
          서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 Amazon Web Services Inc. 개인정보 처리를 위한 인프라
          관리 주식회사 코리아 포트원 유료 서비스 제공을 위한 결제 위탁
        </p>
        <p>
          ② 회사는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적∙관리적
          보호조치, 재위탁 제한, 수탁자에 대한 관리∙감독 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
          개인정보를 안전하게 처리하는지를 감독하고 있습니다.
        </p>
        <p>③ 회사는 아래와 같이 국외의 제3자에게 개인정보 처리업무를 위탁하고 있습니다.</p>
        <p>
          Google LLC. <br />• 이전되는 개인정보 항목
          <br /> • 이전되는 국가: 미국 <br />• 이전일시 및 이전방법: 서비스 이용 시점에 정보통신망을 통해 수시로 이전
          <br /> • 이전받는 자의 성명/연락처: Google LLC. / googlekrsupport@google.com <br />• 이전받는 자의 개인정보
          이용 목적: 개인정보 및 사용자 데이터 분석 <br />• 이전받는 자의 보유ㆍ이용 기간: 회원 탈퇴 또는 이용자격 상실
          이후 즉시 파기(단, 제3조 제2항 또는 제3항이 적용되는 경우 그에 따름)
        </p>
        <p className="title">제6조 (수집목적과 합리적으로 관련된 범위 내의 개인정보 이용 및 제공)</p>
        <p>
          회사는 당초 수집 목적과 합리적인 범위 내에서 아래 각 기준을 고려하여, 이용자의 동의 없이 개인정보를 이용 또는
          제3자에게 제공할 수 있습니다.
        </p>
        <p>
          1. 당초 수집 목적과 관련성이 있는지 여부: 당초 수집 목적과 추가적 이용·제공 목적이 성질이나 경향에 있어 연관이
          있는지 등을 고려하여 따라 판단
          <br /> 2. 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 개인정보의 추가적인 이용 또는 제공에 대한 예측
          가능성이 있는지 여부: 개인정보처리자와 이용자 간의 관계, 기술 수준 및 발전 속도, 상당한 기간동안 정립된
          일반적인 사정(관행) 등을 고려하여 판단 <br />
          3. 이용자의 이익을 부당하게 침해하는지 여부: 추가적인 이용 목적과의 관계에서 이용자의 이익이 실질적으로
          침해되는지 및 해당 이익 침해가 부당한지 등을 고려하여 판단 <br />
          4. 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부: 침해 가능성을 고려한 안전 조치가
          취해지는지 등을 고려하여 판단
        </p>
        <p className="title">제7조 (이용자와 법정대리인의 권리·의무 및 행사방법)</p>
        <p>① 이용자는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
        <p>
          ② 제1항에 따른 권리 행사는 서면, 전자우편 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이
          조치하겠습니다.
        </p>
        <p>
          ③ 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여서 하실 수 있습니다. 이
          경우 수임인에 대한 위임사실을 확인할 수 있는 위임장을 제출하셔야 합니다.
        </p>
        <p>
          ④ 개인정보 보호법 등 관계 법령에서 정하는 바에 따라 이용자의 개인정보 열람·정정·삭제·처리정지 요구 등의 권리
          행사가 제한될 수 있습니다.
        </p>
        <p>
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를
          요구할 수 없습니다.
        </p>
        <p>
          ⑥ 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
          본인이거나 정당한 대리인인지를 확인합니다.
        </p>

        <p className="title">제8조 (개인정보의 파기)</p>
        <p>
          ① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
          개인정보를 파기합니다.
        </p>
        <p>
          ② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 제3조 제2항에
          기재된 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로
          옮기거나 보관장소를 달리하여 보존합니다.
        </p>
        <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>

        <p>
          1. 파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아
          개인정보를 파기합니다. <br />
          2. 파기방법: 회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 기술적 방법을 이용하여
          파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
        </p>

        <p className="title">제9조 (개인정보의 안전성 확보조치)</p>
        <p>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
        <p>
          1. 관리적 조치: 내부관리계획 수립·시행, 정기적 직원 교육 등 <br />
          2. 기술적 조치: 해킹 등에 대비한 기술적 대책개인정보의 암호화, 접속기록의 보관 및 위변조 방지 등<br /> 3.
          물리적 조치: 서버실, 자료보관실 등의 접근통제 등
        </p>

        <p className="title">제10조 (개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)</p>
        <p>
          회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 아래와 같이 이용정보를 저장하고 수시로 불러오는
          ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는
          소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
        </p>
        <p>
          1. 쿠키의 사용목적: 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 서비스 사용 패턴 파악 및 자취 추적,
          보안 접속 여부, 이용자 규모 등을 통해 보안 관리 및 서비스 개선 및 신규 서비스 개발, 맞춤형 서비스 및 광고
          제공에 이용합니다. <br />
          2. 쿠키의 설치∙운영 및 거부: 서비스 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹
          브라우저에서 옵션의 설정을 변경함으로써 쿠키의 저장을 거부할 수 있습니다.
          <br /> 3. 쿠키 저장을 거부할 경우 일부 서비스 이용에 어려움이 발생할 수 있습니다.
        </p>

        <p className="title">제11조 (개인정보 보호책임자)</p>
        <p>
          ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제
          등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <p>• 개인정보 보호 책임자 ◦ 성명: 정상원 ◦ 직책: 대표이사 ◦ 연락처: +82 010-2806-6586, html@marketwiz.com</p>
        <p>
          ② 이용자는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제
          등에 관한 사항을 개인정보 보호책임자 및 아래 담당부서로 문의하실 수 있습니다.
        </p>
        <p>• 부서명: 마켓위즈 CX팀 • 연락처: +82 010-2806-6586, html@marketwiz.com</p>

        <p className="title">제12조 (권익침해 구제방법)</p>
        <p>
          ① 이용자는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원
          개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에
          대하여는 아래의 기관에 문의하시기 바랍니다.
        </p>

        <p>
          • 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr) <br />• 개인정보침해신고센터 : (국번없이)
          118 (privacy.kisa.or.kr)
          <br /> • 대검찰청 : (국번없이) 1301 (대검찰청) <br />• 경찰청 : (국번없이) 182 (사이버범죄 신고시스템 (ECRM))
        </p>

        <p>
          ② 회사는 이용자의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며,
          신고나 상담이 필요한 경우 제11조 제2항에 기재된 담당부서로 연락해 주시기 바랍니다.
        </p>

        <p>
          ③ 개인정보 보호법 제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의
          규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는
          행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
        </p>

        <p>• 중앙행정심판위원회 : (국번없이) 110 (온라인행정심판)</p>

        <p className="title">제13조 (개인정보 처리방침의 변경)</p>
        <p>
          회사는 법률이나 서비스의 변경사항을 반영하기 위한 목적 등으로 개인정보처리방침을 수정할 수 있습니다.
          개인정보처리방침이 변경되는 경우 회사는 변경 사항 최소한 효력발생일 7일 이전에 게시하며, 변경된
          개인정보처리방침은 기재된 효력발생일에 그 효력이 발생합니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경
          등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 미리 알려드리겠습니다.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
