import { atom } from 'recoil';
import { IconName } from '../../components/atoms/Icon/icon';
import { ModalTypes } from '../../hooks/useModal';

export interface ModalType {
  isOpen?: boolean;
  content: JSX.Element | string;
  bottomSheet?: boolean;
}

export interface NoticeType extends ModalType {
  icon?: IconName;
  icon_color?: string;
  type?: ModalTypes;
  title?: string;
  cancel_button_text?: string;
  confirm_button_text?: string;
  confirmHandler?: VoidFunction;
}

export const modalState = atom<ModalType>({
  key: 'modalState',
  default: {
    isOpen: false,
    content: '',
    bottomSheet: false,
  },
});

export const noticeState = atom<NoticeType>({
  key: 'noticeState',
  default: {
    icon: 'notice_bang_mark',
    icon_color: 'primary',
    type: '',
    title: '',
    isOpen: false,
    content: '',
    cancel_button_text: '',
    confirm_button_text: '',
    confirmHandler: () => {},
  },
});
