import { Suspense, lazy } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './assets/styles/index.scss';
import IntroLayoutComponent from './components/Layout/IntroLayoutComponent';
import SellerOfficeLayoutComponent from './components/Layout/SelllerOfficeLayoutComponent';
import Loading from './components/Util/Loading/Loading';
import globalRouter from './globalRouter';
import Privacy from './pages/terms/Privacy';
import Service from './pages/terms/Service';
import userStore from './stores/userStore';

const Intro = lazy(() => import('./pages/Intro'));
const Login = lazy(() => import('./pages/auth/Login'));
const SupportHome = lazy(() => import('./pages/support/SupportHome'));
const TermsHome = lazy(() => import('./pages/terms/TermsHome'));
const Notice = lazy(() => import('./pages/support/Notice/Notice'));
const FAQ = lazy(() => import('./pages/support/FAQ'));
const QNA = lazy(() => import('./pages/support/QNA/QNA'));
const QNAList = lazy(() => import('./pages/support/QNA/QNAList'));
const NoticeDetail = lazy(() => import('./pages/support/Notice/NoticeDetail'));
const Redirect = lazy(() => import('./pages/auth/Redirect'));
const RegisterComplete = lazy(() => import('./pages/member/RegisterComplete'));
const RegisterMember = lazy(() => import('./pages/member/RegisterMember'));
const RegisterSolution = lazy(() => import('./pages/member/RegisterSolution/RegisterSolution'));
const CollectionWaiting = lazy(() => import('./pages/sellerOffice/manageCollection/CollectionWaiting'));
const CollectionSiteCollect = lazy(() => import('./pages/sellerOffice/manageCollection/CollectionSiteCollect'));
const BasicPolicy = lazy(() => import('./pages/sellerOffice/managePolicy/BasicPolicy'));
const TemplatePolicy = lazy(() => import('./pages/sellerOffice/managePolicy/TemplatePolicy'));
const ProductDetail = lazy(() => import('./pages/sellerOffice/manageProductDetail/ManageProductDetail'));
const MarketProduct = lazy(() => import('./pages/sellerOffice/manageProduct/MarketProduct'));
const TranslateImage = lazy(() => import('./pages/sellerOffice/manageProduct/TranslateImage'));
const BasicProduct = lazy(() => import('./pages/sellerOffice/manageProduct/BasicProduct'));
const Category = lazy(() => import('./pages/sellerOffice/manageProduct/Category'));
const CollectPreference = lazy(() => import('./pages/sellerOffice/manageCollection/CollectPreference'));
const BizInfoPreference = lazy(() => import('./pages/sellerOffice/managePreference/BizInfoPreference'));
const MarketInfoPreference = lazy(() => import('./pages/sellerOffice/managePreference/MarketInfoPreference'));
const ServicePreference = lazy(() => import('./pages/sellerOffice/managePreference/ServicePreference'));
const BanWordPreference = lazy(() => import('./pages/sellerOffice/manageCollection/BanWordPreference'));
const OrderPreference = lazy(() => import('./pages/sellerOffice/managePreference/OrderPreference'));
const ProductManagePreference = lazy(() => import('./pages/sellerOffice/managePreference/ProductManagePreference'));
const EachSitePreference = lazy(() => import('./pages/sellerOffice/manageCollection/EachSitePreference'));
const APIPreference = lazy(() => import('./pages/sellerOffice/managePreference/APIPreference'));
const ExchangePreference = lazy(() => import('./pages/sellerOffice/managePreference/ExchangePreference'));
const MsgTemplate = lazy(() => import('./pages/sellerOffice/manageOrder/MsgTemplate'));
// const OrderLog = lazy(() => import('./components/sellerOffice/manageOrder/order/OrderLog'));
const OrderResult = lazy(() => import('./pages/sellerOffice/manageOrder/OrderResult'));
const PriceStockLog = lazy(() => import('./pages/sellerOffice/manageOrder/PriceStockLog'));
const SendMsg = lazy(() => import('./pages/sellerOffice/manageOrder/SendMsg'));
// 로그인 (독립된 레이아웃)
// FO (intro)
// - 회원가입(1차) https://photosynthesisman.github.io/market_wiz/html/FO/F_07_0001.html
// - 가입 완료(1차) https://photosynthesisman.github.io/market_wiz/html/FO/F_07_0002.html
// - 솔루션 신청(1차) https://photosynthesisman.github.io/market_wiz/html/FO/F_02_0001.html
// - 고객센터(2차)
//   - 공지사항
//   - FAQ
//   - Q&A
// SO (seller office)
// - 상품수집관리(1차)
//   - 사이트 상품수집 > 상품수집설정
//   - 저장대기상품
//   - 내 스토어상품수집
// - 상품관리(1차)
// - 정책생성 및 적용관리(1차)
// - 주문· 클레임 관리(1차)

// - 문의관리(2차)
//   - 1:1 문의관리
//   - 쪽지함 관리
// - 환경설정
//   - 서비스 사업자정보 관리
//   - 메뉴권한 관리
//   - 배송대행지 관리

// 레이아웃 구성은 3가지
// - 로그인, FO, SO
//

const App = () => {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  const userState = userStore((state) => state);
  const { unsetLogin } = userStore();

  return (
    <Routes>
      <Route
        path="/auth/login"
        element={
          <Suspense fallback={<Loading />}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path="/terms"
        element={
          <Suspense fallback={<Loading />}>
            <TermsHome />
          </Suspense>
        }>
        <Route path="privacy" element={<Privacy />} />
        <Route path="service" element={<Service />} />
      </Route>

      <Route element={<IntroLayoutComponent />}>
        <Route
          path="/auth/redirect"
          element={
            <Suspense fallback={<Loading />}>
              <Redirect />
            </Suspense>
          }
        />
        <Route
          path="/member/register"
          element={
            <Suspense fallback={<Loading />}>
              <RegisterMember />
            </Suspense>
          }
        />
        <Route
          path="/member/register-complete"
          element={
            <Suspense fallback={<Loading />}>
              <RegisterComplete />
            </Suspense>
          }
        />
        <Route
          path="/member/register-solution/*"
          element={
            <Suspense fallback={<Loading />}>
              <RegisterSolution />
            </Suspense>
          }
        />
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <Intro />
            </Suspense>
          }
        />

        <Route
          path="/support"
          element={
            <Suspense fallback={<Loading />}>
              <SupportHome />
            </Suspense>
          }>
          <Route path="notice" element={<Notice />} />
          <Route path="notice/:id" element={<NoticeDetail />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="qna" element={<QNA />} />
          <Route path="qna/list" element={<QNAList />} />
        </Route>
      </Route>
      {/* SO 페이지 부터는 인증필요() */}
      <Route path="/sellerOffice" element={<SellerOfficeLayoutComponent />}>
        <Route
          path="manageCollection/waiting"
          element={
            <Suspense fallback={<Loading />}>
              <CollectionWaiting />
            </Suspense>
          }
        />
        <Route
          path="manageCollection/siteCollect"
          element={
            <Suspense fallback={<Loading />}>
              <CollectionSiteCollect />
            </Suspense>
          }
        />
        <Route
          path="managePolicy/basic"
          element={
            <Suspense fallback={<Loading />}>
              <BasicPolicy />
            </Suspense>
          }
        />
        <Route
          path="managePolicy/template"
          element={
            <Suspense fallback={<Loading />}>
              <TemplatePolicy />
            </Suspense>
          }
        />
        {/* 상품관리 - 기본 상품 관리 */}
        <Route
          path="manageProduct/basic"
          element={
            <Suspense fallback={<Loading />}>
              <BasicProduct />
            </Suspense>
          }
        />

        {/* 기본 상품 상세 */}
        <Route
          path="manageProduct/basic/:id"
          element={
            <Suspense fallback={<Loading />}>
              <ProductDetail />
            </Suspense>
          }
        />

        {/* 상품관리 - 마켓 상품 관리 - 상품업데이트/마켓전송 */}
        <Route
          path="manageProduct/market"
          element={
            <Suspense fallback={<Loading />}>
              <MarketProduct />
            </Suspense>
          }
        />

        {/* 마켓 상품 상세 */}
        <Route
          path="manageProduct/market/:id"
          element={
            <Suspense fallback={<Loading />}>
              <ProductDetail />
            </Suspense>
          }
        />

        {/* 상품관리 - 마켓 상품 관리 - 상품이미지번역/복원 */}
        <Route
          path="manageProduct/translate"
          element={
            <Suspense fallback={<Loading />}>
              <TranslateImage />
            </Suspense>
          }
        />
        <Route
          path="manageProduct/category"
          element={
            <Suspense fallback={<Loading />}>
              <Category />
            </Suspense>
          }
        />
        <Route
          path="manageCollection/collect"
          element={
            <Suspense fallback={<Loading />}>
              <CollectPreference />
            </Suspense>
          }
        />
        <Route
          path="managePreference/bizinfo"
          element={
            <Suspense fallback={<Loading />}>
              <BizInfoPreference />
            </Suspense>
          }
        />
        <Route
          path="managePreference/apikey"
          element={
            <Suspense fallback={<Loading />}>
              <APIPreference />
            </Suspense>
          }
        />
        <Route
          path="managePreference/service"
          element={
            <Suspense fallback={<Loading />}>
              <ServicePreference />
            </Suspense>
          }
        />
        <Route
          path="managePreference/marketinfo"
          element={
            <Suspense fallback={<Loading />}>
              <MarketInfoPreference />
            </Suspense>
          }
        />
        <Route
          path="manageCollection/banword"
          element={
            <Suspense fallback={<Loading />}>
              <BanWordPreference />
            </Suspense>
          }
        />
        <Route
          path="manageProduct/product"
          element={
            <Suspense fallback={<Loading />}>
              <ProductManagePreference />
            </Suspense>
          }
        />
        <Route
          path="managePreference/order"
          element={
            <Suspense fallback={<Loading />}>
              <OrderPreference />
            </Suspense>
          }
        />
        <Route
          path="manageCollection/eachsite"
          element={
            <Suspense fallback={<Loading />}>
              <EachSitePreference />
            </Suspense>
          }
        />
        <Route
          path="managePreference/exchange"
          element={
            <Suspense fallback={<Loading />}>
              <ExchangePreference />
            </Suspense>
          }
        />
        <Route path="manageOrder">
          <Route
            path="orderResult"
            element={
              <Suspense fallback={<Loading />}>
                <OrderResult />
              </Suspense>
            }
          />
          <Route
            path="priceStockLog"
            element={
              <Suspense fallback={<Loading />}>
                <PriceStockLog />
              </Suspense>
            }
          />
          <Route
            path="sendMsg"
            element={
              <Suspense fallback={<Loading />}>
                <SendMsg />
              </Suspense>
            }
          />
          <Route
            path="msgTemplate"
            element={
              <Suspense fallback={<Loading />}>
                <MsgTemplate />
              </Suspense>
            }
          />
        </Route>
        {/* dashboard 전까진 사이트 상품수집이 메인 페이지 */}
        <Route
          index
          element={
            <Suspense fallback={<Loading />}>
              <CollectionSiteCollect />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
export default App;
