// import { IconType } from "components/organism/GlobalNotice/NoticeIcon";
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { modalState, ModalType, noticeState, NoticeType } from '../recoil/atom/modal';
export type ModalTypes = string | 'confirm' | 'alert';
export type ModalIconTypes = '' | 'mail' | 'caution';

export const useModal = () => {
  const [modalDataState, setModalDataState] = useRecoilState<ModalType>(modalState);
  const [noticeDataState, setNoticeDataState] = useRecoilState(noticeState);

  const closeModal = useCallback(() => {
    document.body.style.overflow = 'auto';
    setModalDataState((prev) => {
      return { ...prev, isOpen: false };
    });
  }, [setModalDataState]);

  const closeNotice = useCallback(() => {
    document.body.style.overflow = 'auto';
    setNoticeDataState((prev) => {
      return { ...prev, isOpen: false };
    });
  }, [setNoticeDataState]);

  const openModal = useCallback(
    ({ content, bottomSheet = false }: ModalType) => {
      document.body.style.overflow = 'hidden';

      setModalDataState({
        isOpen: true,
        content: content,
        bottomSheet: bottomSheet,
      });
    },

    [setModalDataState]
  );

  const openNotice = useCallback(
    ({
      icon,
      icon_color,
      type,
      title,
      content,
      cancel_button_text,
      confirm_button_text,
      confirmHandler,
    }: NoticeType) => {
      document.body.style.overflow = 'hidden';
      type === 'confirm' &&
        setNoticeDataState({
          icon: icon,
          icon_color: icon_color,
          type: type,
          isOpen: true,
          title: title,
          content: content,
          cancel_button_text: cancel_button_text,
          confirm_button_text: confirm_button_text,
          confirmHandler: confirmHandler,
        });
      type === 'alert' &&
        setNoticeDataState({
          icon: icon,
          icon_color: icon_color,
          type: type,
          isOpen: true,
          title: title,
          cancel_button_text: cancel_button_text,
          content: content,
          confirmHandler: confirmHandler,
        });
    },

    [setNoticeDataState]
  );

  const Modal = ({ content, bottomSheet }: ModalType) => {
    const ModalData: ModalType = {
      content: content,
      bottomSheet: bottomSheet,
    };
    return openModal(ModalData);
  };

  const Notice = ({
    icon,
    icon_color,
    type,
    title,
    content,
    cancel_button_text,
    confirm_button_text,
    confirmHandler,
  }: NoticeType) => {
    const NoticeData: NoticeType = {
      icon: icon,
      icon_color: icon_color,
      type: type,
      title: title,
      content: content,
      cancel_button_text: cancel_button_text,
      confirm_button_text: confirm_button_text,
      confirmHandler: confirmHandler,
    };
    return openNotice(NoticeData);
  };

  return {
    modalDataState,
    noticeDataState,
    closeModal,
    closeNotice,
    Modal,
    Notice,
  };
};
