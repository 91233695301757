import { AnimatePresence } from 'framer-motion';
// import { variants } from "shared/variants";
import { useModal } from '../../../hooks/useModal';
import { Dim } from '../../atoms/Dim';
import { StyledGlobalModal } from './index.styled';

const GlobalModal = () => {
  const { modalDataState, closeModal } = useModal();

  return (
    <AnimatePresence>
      {modalDataState.isOpen && (
        <StyledGlobalModal.GlobalContainer
          variants={{
            initial: {
              opacity: 0,
            },
            animate: {
              opacity: 1,
            },
            exit: {
              opacity: 0,
              transition: {
                ease: 'ease',
                duration: 0.2,
              },
            },
          }}
          initial="initial"
          animate="animate"
          exit="exit">
          <Dim onClick={closeModal} />
          <StyledGlobalModal.GlobalContents>{modalDataState.content}</StyledGlobalModal.GlobalContents>
        </StyledGlobalModal.GlobalContainer>
      )}
    </AnimatePresence>
  );
};

export default GlobalModal;
