import { Link } from 'react-router-dom';
import { popupStore } from '../../../stores/popupStore';
import ChatButton from '../../Util/Chat/ChatButton';

const IntroLayoutFooter = () => {
  const { openPopup } = popupStore();

  return (
    <footer>
      <div className="footer-space">
        <div className="footer-contents">
          <div className="flex space-between">
            <div className="left">
              <div className="flex align-center">
                <h3>상담안내</h3>

                <ChatButton classname={'button btn-chat mini ml-8'} text="채팅상담" />
              </div>
              <p>평일 오전10시 ~ 오후5시(토/일/공휴일 제외)</p>
            </div>
            <div className="right">
              <div className="t-right policy-info">
                <Link to="/terms/service">이용약관</Link>
                <Link to="terms/privacy">개인정보처리방침</Link>
                {/* <Link to="" onClick={() => openPopup('center', '환불정책', <FooterWithDraw />, '확인')}>
                  환불정책
                </Link>
                <Link to="" title="지적재산신고 페이지로 이동">
                  지적재산신고
                </Link> */}
              </div>
              <div className="t-right company-info">
                <div>
                  <dl>
                    <dt>회사명</dt>
                    <dd>(주)마켓위즈</dd>
                  </dl>

                  <dl>
                    <dt>본사주소</dt>
                    <dd>서울특별시 마포구 토정로 260</dd>
                  </dl>
                  <dl>
                    <dt>전화번호</dt>
                    <dd>010-2806-6586</dd>
                  </dl>
                  <dl>
                    <dt>사업자등록번호</dt>
                    <dd>520-86-02938</dd>
                  </dl>
                </div>
                <div>
                  <dl>
                    <dt>통신판매업신고</dt>
                    <dd>제 2024-서울마포-1720 호</dd>
                  </dl>
                  <dl>
                    <dt>대표이사</dt>
                    <dd>정상원</dd>
                  </dl>
                  <dl>
                    <dt>개인정보책임자</dt>
                    <dd>(주)마켓위즈</dd>
                  </dl>
                  <dl>
                    <dt>이용문의</dt>
                    <dd>help@marketwiz.com</dd>
                  </dl>
                </div>
                <div className="flex align-center justify-end">
                  <span className="mr-4">고객님의 안전거래를 위해 에스크로 서비스에 가입되어 있습니다.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="bottom-contents">
            <h2 className="logo">
              <Link to={'/'} title="홈페이지 바로가기">
                logo
              </Link>
            </h2>
            <p>All Rights Reserved. Marketwiz All Rights</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default IntroLayoutFooter;
