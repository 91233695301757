import axios from 'axios';
import { AES, enc, mode } from 'crypto-js';
import instance from '../apis/Request';
import { warningBar } from '../components/Util/SnackBar/SnackBar';
import { ORDER_STATUS, translateCode } from '../constant/commonConst';
import commonStore from '../stores/commonStore';
import userStore from '../stores/userStore';
import { DetailTemplateInfoType } from '../types/template';

export const _1MB = 1024 ** 1;
export const _50MB = 1024 ** 50;

export const WIZ_URL = 'https://dev.market-wiz.com/api/v1';
export const PROXY_URL =
  process.env.REACT_APP_ENV === 'dev' ? 'https://dev.market-wiz.com' : 'https://api.market-wiz.com';

// 특수문자 제거 정규식
const removedSCTextReg = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim;
const addNumberCommaReg = /\B(?=(\d{3})+(?!\d))/g;
export type imgTransReqType = {
  imgType: string;
  imgStr: string;
  imgName: string; // 네이밍규칙??
  sourceLang: string;
  targetLang: string;
};

export const aesDecrypt = (encryptedData: string): string => {
  const keyStr = process.env.REACT_APP_ENCRYPT_KEY;
  const ivStr = process.env.REACT_APP_ENCRYPT_iv;

  const cipher = AES.decrypt(
    enc.Base64.parse(encryptedData).toString(enc.Utf8),
    enc.Utf8.parse(keyStr.padEnd(32, '\0')),
    {
      iv: enc.Utf8.parse(ivStr),
      mode: mode.CBC,
    }
  );

  return cipher.toString(enc.Utf8);
};

export const division = <T>(arr: T[], count: number): T[][] => {
  const divide = Math.ceil(arr.length / count);
  const newArray = [];

  for (let i = 0; i < divide; i++) {
    // 배열 0부터 n개씩 잘라 새 배열에 넣기
    newArray.push(arr.splice(0, count));
  }

  return newArray;
};

export const numberFormat = new Intl.NumberFormat('ko-KR');

export const getBanwordTemplates = async () => {
  const { data } = await instance.get('settings/banwordTemplate');
  return data.responseData.banwordTemplateList;
};

export const checkParseInt = (val: string | number) => (typeof val === 'string' ? parseInt(val) : val);

export const getByte = (str: string) =>
  str
    .split('')
    .map((s) => s.charCodeAt(0))
    .reduce((prev, c) => prev + (c === 10 ? 2 : c >> 7 ? 2 : 1), 0);

export const translateImg = async (params) => {
  try {
    if (!userStore.getState().getUserInfo().slrSeq) {
      warningBar('로그인이 필요한 기능입니다.');
      return false;
    }
    if (
      !commonStore.getState().apiKeyList?.ohooInfo?.apiVal ||
      commonStore.getState().apiKeyList?.ohooInfo?.apiVal === null
    ) {
      warningBar('오후 스튜디오 api key를 입력하세요.');
      return false;
    }
    const header = {
      'X-OHOO-API-KEY': commonStore.getState().apiKeyList.ohooInfo.apiVal,
      'X-OHOO-SUB-USER': userStore.getState().getUserInfo().slrSeq,
    };

    const { data } = await axios.post('/studio/v1/image/translate', params, { headers: { ...header } });
    return data.responses;
  } catch (error) {
    console.error(error);
    warningBar('나중에 다시 시도하세요.');
    return false;
  }
};

//
export const uploadImage = async (imgData: string, uploadFolder: string, mimeType: string) => {
  const { data: imgUploadResponse } = await axios.post(`${WIZ_URL}/common/uploadImage`, {
    imgData: imgData,
    imgFolder: uploadFolder,
    mimeType: mimeType,
  });

  return imgUploadResponse.responseData.url;
};

export const getMimeType = (contentType: string) => {
  let mimeType = '';
  switch (contentType) {
    case 'image/png':
      mimeType = 'png';
      break;
    case 'image/jpg':
    case 'image/jpeg':
      mimeType = 'jpg';
      break;

    case 'image/gif':
      mimeType = 'gif';
      break;
    case 'image/webp':
      mimeType = 'webp';
      break;
  }

  return mimeType;
};

const getUrlCleamParam = (url: string) => {
  const urlObj = new URL(url);
  return `${urlObj.origin}${urlObj.pathname}`;
};

export const uploadImageNew = async (imgData: string, uploadFolder: string, contentType: string) => {
  const mimeType = getMimeType(contentType);

  // S3 PreSignedUrl 생성
  const { data: imgUploadResponse } = await instance.post('/common/s3/url', {
    imgFolder: uploadFolder,
    mimeType: mimeType,
    expiration: 30,
  });

  const { preSignedUrl } = imgUploadResponse.responseData;

  const base64Response = await fetch(imgData);
  const blob = await base64Response.blob();

  const { data } = await instance.put(preSignedUrl, blob, { headers: { 'Content-Type': blob.type } });

  const urlWithoutQueryParams = getUrlCleamParam(preSignedUrl);

  return urlWithoutQueryParams;
};

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  let bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

export const toDataURL = async (url: string): Promise<any> => {
  // cors 이슈로 bizunit 생성 및 호출

  if (url.startsWith('data:')) {
    const found = url.match(/data:\S*;base64/g);
    return { dataUrl: url, contentType: found && found[0].slice('data:'.length, ';base64'.length * -1) };
  } else {
    // SyncTree 통해 호출

    const response = await instance.get('common/get-image-binary', {
      timeout: 120000,
      params: { imgUrl: url },
    });
    const base64Image = response.data;
    const image = `data:${response.headers['content-type']};base64,${base64Image}`;
    return { dataUrl: image, contentType: response.headers['content-type'] };

    // 직접호출

    // const response = await axios.get(url, {
    //   responseType: 'arraybuffer',
    //   timeout: 180 * 1000,
    // });
    // const contentType = response.headers['content-type'];

    // let base64ImageString = arrayBufferToBase64(response.data);

    // return { dataUrl: `data:${contentType};base64,${base64ImageString}`, contentType: contentType };
  }
};

export const translateText = async (tpCode: string, sourceLang: string, targetLang: string, text: string) => {
  const { data } = await instance.post('/products/translate', {
    API_TP_CODE: tpCode,
    beforeLanguage: translateCode[sourceLang],
    afterLanguage: translateCode[targetLang],
    translateText: text,
  });
  return data.responseData;
};

export const getOrderStatus = (id: string) => ORDER_STATUS.filter((status) => status.id === id)[0].name;

export const getRandomString = () => Math.random().toString(36).slice(2, 11);

// 마켓별 이름으로 바꿔줌
export const getMarketName = (seq: number, marketList: any) => {
  const market = marketList.filter(({ uploadMarketSeq }) => uploadMarketSeq === seq);

  return market.length === 0 ? '' : market[0].shopName;
};

// text -> 특수문자에 공백 삽입 후 리턴
export const removedSCText = (s: string) => {
  return s.replace(removedSCTextReg, ' ');
};

// 세자리 숫자마다 , 삽입
export const addComma = (e: any) => {
  let number = e ? e.toString().replace(/[^0-9]/gi, '') : '';

  if (isNaN(number)) number = '';

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// input text 숫자만
export const getNumberOnly = (event: React.KeyboardEvent<HTMLInputElement>) => {
  const input = event.target as HTMLInputElement;
  input.value = input.value.replace(/[^0-9]/g, '');
};

// YYYY-MM-DD HH:MM:SS -> YYYY-MM-DD
export const getYYYYMMDD = (date: string) => {
  const at = date.split(' ') && date.split(' ').length > 0 ? date.split(' ')[0] : date;

  return at;
};

// 상품 상세페이지 고시정보를 JSON 형태로 바꿔줌
// <x>고시정보</x> 식으로 분기 설정
export const parseProductInfo = (htmlString: string) => {
  // Step 1: JSON 데이터 추출
  const regex = /<x>([\s\S]*?)<\/x>/;
  const match = htmlString.match(regex);

  if (match) {
    try {
      // JSON 문자열 파싱
      const jsonObject = JSON.parse(match[1].replace(/'/g, '"')); // '를 "로 변환
      const tableHTML = generateTableHTML(jsonObject);

      // JSON 데이터를 테이블 HTML로 치환
      return htmlString.replace(regex, insertNewline(tableHTML));
    } catch (error) {
      console.error('JSON 파싱 실패:', error);
    }
  }
  return htmlString; // JSON 데이터가 없으면 원본 반환
};

// 고시정보를 테이블 형태로 변환
const generateTableHTML = (jsonObject: string) => {
  const rows = Object.entries(jsonObject)
    .map(([key, value]) => {
      return `<tr><td style="border: 1px solid #ddd; padding: 8px;">${key}</td><td style="border: 1px solid #ddd; padding: 8px;">${value}</td></tr>`;
    })
    .join('');

  return `
    <table style="border-collapse: collapse; width: 100%; border="1"; margin-top: 16px;">
      <thead>
        <tr style="background-color: rgb(236, 240, 241);">
          <th style="width: 8%;">항목</th>
          <th style="width: 92%;">내용</th>
        </tr>
      </thead>
      <tbody>
        ${rows}
      </tbody>
    </table>
  `;
};

// 1. 2. / 가. 나. 같은 리스트 형식에 개행값을 넣는 함수
export const insertNewline = (text: string) => {
  // 정규식: 숫자(1., 2., ...) 또는 한글 문자(가., 나., 다.) 뒤에 점(.)으로 시작하는 패턴 매칭
  const regex = /(\d\.|[가-힣]\.)/g;

  // 매칭된 부분을 <div>...</div>로 감싸기
  const updatedText = text.replace(regex, '<div>$1');

  // <div> 시작 태그 이후 데이터 끝에 </div> 추가
  const result = updatedText.replace(/<\/div>([^<]+)/g, '$1</div>');

  return result;
};

// 에디터 이미지 추가로직
export const addImageToEditor = (url: string, stringHtml: string, position: 'front' | 'back') => {
  // 새로운 이미지 태그
  const imgTag = `<img src="${url}" alt="inserted image ${position}" data-align="center" style="max-width: 100%; display: block; margin-left: auto; margin-right: auto;"/>`;

  return `${position === 'front' ? `${imgTag}<br>${stringHtml}` : `${stringHtml}<br>${imgTag}`}`;
};

// 초기 랜더링시 상단, 하단 이미지를 포함하여 렌더
export const includeTopBottomImg = (imgs: DetailTemplateInfoType, current: string) => {
  if (!imgs) return current;
  const topImg = `<img src="${imgs.topFixUrl}" alt="inserted image top" data-align="center" style="max-width: 100%; display: block; margin-left: auto; margin-right: auto;"/><br>`;

  const bottomImg = `<br><img src="${imgs.bottomFixUrl}" alt="inserted Image bottom" data-align="center" style="max-width: 100%; display: block; margin-left: auto; margin-right: auto;"/>`;

  return `${imgs.topFixImageUseYN === 'Y' ? topImg : ''}${current}${imgs.bottomFixImageUseYN === 'Y' ? bottomImg : ''}`;
};

// market seq 에 따라 한글 마켓 이름 리턴
export const getStoreNameBySeq = (seq: number) => {
  return seq === 1005 ? '스마트 스토어' : seq === 1001 ? '쿠팡' : '11번가';
};

export const getStoreState = (seq: number) => {
  return seq === 1005 ? 'smartStore' : seq === 1001 ? 'coupang' : '_11st';
};

// marketSeq to market icon
export const convertMarketToIcon = (seq: number) => {
  return seq === 1005
    ? 'https://market-wiz-static.s3.ap-northeast-2.amazonaws.com/icon/smart-store.png'
    : seq === 1001
    ? 'https://market-wiz-static.s3.ap-northeast-2.amazonaws.com/icon/coupang.png'
    : 'https://market-wiz-static.s3.ap-northeast-2.amazonaws.com/icon/11st.png';
};

export const download = (url: string, fileName: string) => {
  const linkElement = document.createElement('a');
  linkElement.download = fileName;
  linkElement.href = url;
  linkElement.style.display = 'none';

  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};
